/**
 * This is a copy of the Sign Up form that Azure injects into <div id="api"></div>
 * It exists here to provide classNames to reference for styling and istemporarily removed
 * from the DOM when a developer clicks the "Copy HTML" button.
 */

export const AzureSignUpForm = (props: { showErrors: boolean }) => {
  const errorDisplay = props.showErrors ? "block" : "none"

  return (
    <div id="api" data-name="SelfAsserted">
      <div className="heading">
        <h1>User Details</h1>
      </div>
      <div className="buttons"></div>
      <div className="intro">
        <p>Please provide the following details.</p>
      </div>
      <form id="attributeVerification">
        <div
          id="passwordEntryMismatch"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        >
          The password entry fields do not match. Please enter the same password
          in both fields and try again.
        </div>
        <div
          id="requiredFieldMissing"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        >
          A required field is missing. Please fill out all required fields and
          try again.
        </div>
        <div
          id="fieldIncorrect"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        >
          One or more fields are filled out incorrectly. Please check your
          entries and try again.
        </div>
        <div
          id="claimVerificationServerError"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        ></div>
        <div id="attributeList" className="attr">
          <ul>
            <li className="EmailBox email_li">
              <div className="attrEntry">
                <label id="email_label" htmlFor="email">
                  Email Address
                </label>
                <div className="error itemLevel" role="alert"></div>
                <input
                  id="email"
                  className="textInput"
                  type="email"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  pattern="^[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
                  title="Email address that can be used to contact you."
                  autoFocus={true}
                />
                <a
                  className="helpLink tiny"
                  href="_"
                  data-help="Email address that can be used to contact you."
                >
                  What is this?
                </a>
              </div>
            </li>
            <li className="Password newPassword_li">
              <div className="attrEntry">
                <label id="newPassword_label" htmlFor="newPassword">
                  New Password
                </label>
                <div className="error itemLevel" role="alert"></div>
                <input
                  id="newPassword"
                  className="textInput"
                  type="password"
                  placeholder="New Password"
                  aria-label="New Password"
                  title="Enter new password"
                  autoComplete="new-password"
                  aria-required="true"
                />
                <a
                  className="helpLink tiny"
                  href="_"
                  data-help="Enter new password"
                >
                  What is this?
                </a>
              </div>
            </li>
            <li className="Password reenterPassword_li">
              <div className="attrEntry">
                <label id="reenterPassword_label" htmlFor="reenterPassword">
                  Confirm New Password
                </label>
                <div className="error itemLevel" role="alert"></div>
                <input
                  id="reenterPassword"
                  className="textInput"
                  type="password"
                  placeholder="Confirm New Password"
                  aria-label="Confirm New Password"
                  title="Confirm new password"
                  autoComplete="new-password"
                  aria-required="true"
                />
                <a
                  className="helpLink tiny"
                  href="_"
                  data-help="Confirm new password"
                >
                  What is this?
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons">
          <button
            id="continue"
            type="submit"
            form="attributeVerification"
            aria-disabled="false"
            aria-label="Create"
          >
            Create
          </button>
          <button id="cancel" aria-label="Cancel" formNoValidate={true}>
            Cancel
          </button>
        </div>

        <div className="verifying-modal">
          <div id="verifying_blurb"></div>
        </div>
      </form>
    </div>
  )
}
