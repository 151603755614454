import {
  BoxProps,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  Flex,
  IconButtonProps,
  Text,
  useToast,
  CardHeader,
  Card,
  CardBody,
  ButtonGroup,
  Stack,
} from "@chakra-ui/react"
import { College, COLLEGES } from "data"
import {
  ArrowBackIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CopyIcon,
  DownloadIcon,
  PlusSquareIcon,
  QuestionOutlineIcon,
  StarIcon,
} from "@chakra-ui/icons"
import { ThrowErrorButton } from "devTools/ThrowErrorButton"
import { useErrorModal } from "context/ErrorContext"
import { RouterLink } from "components"
import { AppRoutes } from "utilities/constants"

const downloadCollegeData = (college: College) => {
  const fileName = `${college.name}.json`
  const jsonContent = JSON.stringify(college.data, null, 2)
  const blob = new Blob([jsonContent], { type: "application/json" })
  const url = URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)

  return fileName
}

const copyCollegeData = (college: College) => {
  const jsonContent = JSON.stringify(college.data, null, 2)
  navigator.clipboard.writeText(jsonContent)
}

export const DevToolsSidebar = ({ children }: BoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { handleError } = useErrorModal()

  const exclusions = ["twoOcean"]

  const collegeData = COLLEGES.filter(
    college => !exclusions.includes(college.name),
  ).sort((a, b) => a.name.localeCompare(b.name))

  const handleDownload = (college: College) => {
    const fileName = downloadCollegeData(college)
    toast({
      title: `(${college.name}) ${fileName} downloaded`,
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  const handleCopy = (college: College) => {
    copyCollegeData(college)
    toast({
      title: `${college.name} JSON copied`,
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  return (
    <>
      <DevToolsTrigger open onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <Flex direction="column" height="100%">
            <DrawerHeader borderBottomWidth="1px">Dev Tools</DrawerHeader>
            <Stack as={DrawerBody} padding={4} gap={4}>
              <Group title="College Data">
                <VStack spacing={0} align="stretch">
                  {collegeData.map(college => (
                    <Flex
                      key={college.name}
                      justifyContent="space-between"
                      alignItems="center"
                      borderTopWidth="1px"
                      paddingY={1}
                      _last={{
                        borderBottomWidth: "0",
                        paddingBottom: "0",
                      }}
                    >
                      <Text
                        textTransform="capitalize"
                        fontSize="medium"
                        margin="0!"
                      >
                        {college.name} JSON
                      </Text>
                      <ButtonGroup size="sm" variant="ghost" spacing={0}>
                        <Button
                          leftIcon={<CopyIcon boxSize="5" />}
                          aria-label={`Copy ${college.name} JSON`}
                          onClick={() => handleCopy(college)}
                        >
                          Copy
                        </Button>
                        <Button
                          leftIcon={<DownloadIcon boxSize="5" />}
                          aria-label={`Download ${college.name} JSON`}
                          onClick={() => handleDownload(college)}
                        >
                          Download
                        </Button>
                      </ButtonGroup>
                    </Flex>
                  ))}
                </VStack>
              </Group>

              <Group title="Auth Pages">
                <ButtonGroup variant="link" size="md" gap={4}>
                  <Button
                    as={RouterLink}
                    to={AppRoutes.authSignUp}
                    leftIcon={<PlusSquareIcon />}
                    onClick={onClose}
                  >
                    Sign Up
                  </Button>
                  <Button
                    as={RouterLink}
                    to={AppRoutes.authSignIn}
                    leftIcon={<StarIcon />}
                    onClick={onClose}
                  >
                    Sign In
                  </Button>
                  <Button
                    as={RouterLink}
                    to={AppRoutes.authForgotPassword}
                    leftIcon={<QuestionOutlineIcon />}
                    onClick={onClose}
                  >
                    Forgot Password
                  </Button>
                </ButtonGroup>
              </Group>
              <Group title="Actions">
                <ButtonGroup spacing="4">
                  <Button
                    as={RouterLink}
                    to={"/"}
                    variant="outline"
                    leftIcon={<ArrowBackIcon />}
                  >
                    Go Home
                  </Button>
                  <ThrowErrorButton
                    onClick={async () => {
                      onClose()
                      await new Promise(resolve => setTimeout(resolve, 500))
                      handleError({
                        title: "Example of an Error",
                        message: "Test error triggered from DevTools.",
                        log: "Test error triggered from DevTools.",
                      })
                    }}
                  />
                </ButtonGroup>
              </Group>
            </Stack>
            <DevToolsTrigger close onClick={onClose} />
          </Flex>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const Group = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => (
  <Card>
    <CardHeader fontWeight="bold" pb="0">
      {title}
    </CardHeader>
    <CardBody py={3}>{children}</CardBody>
  </Card>
)

interface DevToolsTriggerProps extends Omit<IconButtonProps, "aria-label"> {
  open?: boolean
  close?: boolean
}
const DevToolsTrigger = ({ open, close, ...props }: DevToolsTriggerProps) => {
  const commonProps: IconButtonProps = {
    id: "dev-tools-trigger",
    "aria-label": open ? "Open Dev Tools" : "Close Dev Tools",
    icon: open ? <ArrowRightIcon /> : <ArrowLeftIcon />,
    variant: "outline",
    colorScheme: "teal",
    zIndex: 100,
    minHeight: 12,
    minWidth: 10,
    borderRadius: 0,
    boxShadow: "none!",
    top: 1,
    title: props["aria-label"],
    transition: "transform 0.2s",
    _hover: {
      transform: open ? "translateX(4px)" : null,
    },
    ...props,
  }

  const positionProps = open
    ? { left: -1, position: "fixed" as const }
    : { right: -6, position: "absolute" as const }

  return <IconButton {...commonProps} {...positionProps} />
}
