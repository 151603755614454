import { Box, BoxProps, Text, useStyleConfig } from "@chakra-ui/react"
import { H1 } from "components"
import { AuthFormWrapper, AuthTextContent } from "components/LayoutComponents"
import { ApplicationData } from "types"
import { AppRoutes } from "utilities/constants"
import { AuthToolbar } from "pages/susi/utils/AuthToolbar"
import {
  useAuthHead,
  useForgotPasswordScript,
  useSignInScript,
  useSignUpScript,
} from "pages/susi/hooks"
import { AzureSignUpForm } from "pages/susi/forms/AzureSignUpForm"
import { AzureSignInForm } from "pages/susi/forms/AzureSignInForm"
import { AzureForgotPasswordForm } from "pages/susi/forms/AzureForgotPasswordForm"

interface AuthPageProps extends BoxProps {
  applicationData: ApplicationData
  pageName:
    | AppRoutes.authForgotPassword
    | AppRoutes.authSignIn
    | AppRoutes.authSignUp
}

/*
  // Sample Link to Sign Up Page
  https://sienaqab2c.b2clogin.com/sienaqab2c.onmicrosoft.com/oauth2/v2.0/authorize?p=b2c_1a_signup&client_id=19c4bf01-6b56-41db-8b3f-49b02d72fa33&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login#

   // Sample Link to Sign In Page
  https://sienaqab2c.b2clogin.com/sienaqab2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1a_signin&client_id=19c4bf01-6b56-41db-8b3f-49b02d72fa33&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms&scope=openid&response_type=id_token&prompt=login#

  // Sample Link to Forgot Password Page
  https://sienaqab2c.b2clogin.com/sienaqab2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1a_forgotpassword&client_id=19c4bf01-6b56-41db-8b3f-49b02d72fa33&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapply.sienacollege.org.qa.two-ocean.dev&scope=openid&response_type=id_token&prompt=login
*/

export const DefaultAuthPage = ({
  applicationData,
  pageName,
  ...rest
}: AuthPageProps) => {
  useAuthHead(applicationData)
  const styles = useStyleConfig("AuthPage")

  const isSignIn = pageName === AppRoutes.authSignIn
  const isSignUp = pageName === AppRoutes.authSignUp
  const isForgotPassword = pageName === AppRoutes.authForgotPassword

  return (
    <Box id={pageName} __css={styles} {...rest}>
      <AuthToolbar route={pageName} />
      <Box id="authPageWrapper">
        {isSignIn && <SignInForm {...applicationData} />}
        {isSignUp && <SignUpForm {...applicationData} />}
        {isForgotPassword && <ForgotPasswordForm {...applicationData} />}
      </Box>
    </Box>
  )
}

const SignInForm = (applicationData: ApplicationData) => {
  useSignInScript()
  const { signIn } = applicationData.susiPages

  const decodedHTMLContent = {
    __html: decodeURIComponent(signIn.htmlContent),
  }

  return (
    <>
      <AuthTextContent>
        <H1>{signIn.heading}</H1>
      </AuthTextContent>

      <AuthTextContent dangerouslySetInnerHTML={decodedHTMLContent} />

      {/* If user is returning from password reset page */}
      <Text
        id="passwordResetConfirmation"
        align="center"
        fontWeight="bold"
        display="none"
      >
        Password reset successful. Please use new password below.
      </Text>

      <AuthFormWrapper>
        <AzureSignInForm showErrors={false} />
      </AuthFormWrapper>
    </>
  )
}

const SignUpForm = (applicationData: ApplicationData) => {
  useSignUpScript()
  const { signUp } = applicationData.susiPages

  const subheading = {
    __html: decodeURIComponent(signUp.subheading),
  }

  const htmlContent = {
    __html: decodeURIComponent(signUp.htmlContent),
  }

  const formHeading = {
    __html: decodeURIComponent(signUp.formHeading),
  }

  return (
    <>
      <AuthTextContent gridArea="heading">
        <H1>{signUp.heading}</H1>
        <Text dangerouslySetInnerHTML={subheading} />
      </AuthTextContent>

      <AuthFormWrapper gridArea="form">
        <Text id="form-heading" dangerouslySetInnerHTML={formHeading} />
        <AzureSignUpForm showErrors={false} />
        <Text id="form-subtext">
          Already started an application?
          {/* eslint-disable-next-line */}
          <a className="userflow">Sign In</a>
        </Text>
      </AuthFormWrapper>

      <AuthTextContent
        gridArea="description"
        dangerouslySetInnerHTML={htmlContent}
      />
    </>
  )
}

const ForgotPasswordForm = (applicationData: ApplicationData) => {
  useForgotPasswordScript()
  const { forgotPassword } = applicationData.susiPages

  return (
    <>
      <AuthTextContent>
        <H1>{forgotPassword.heading}</H1>
      </AuthTextContent>

      <AuthFormWrapper>
        <AzureForgotPasswordForm showErrors={false} />
      </AuthFormWrapper>
    </>
  )
}
