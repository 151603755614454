/**
 * This is a copy of the Sign In form that Azure injects into <div id="api"></div>
 * It exists here to provide classNames to reference for styling and istemporarily removed
 * from the DOM when a developer clicks the "Copy HTML" button.
 */

export const AzureSignInForm = (props: { showErrors: boolean }) => {
  const errorDisplay = props.showErrors ? "block" : "none"

  return (
    <div id="api" data-name="Unified">
      <div className="heading">
        <h1>Sign in</h1>
      </div>
      <form
        id="localAccountForm"
        className="localAccount"
        aria-label="Sign in with your email address"
      >
        <div className="intro">
          <h2>Sign in with your email address</h2>
        </div>
        <div
          className="error pageLevel"
          aria-hidden="true"
          role="alert"
          style={{ display: errorDisplay }}
        >
          <p></p>
        </div>
        <div className="entry">
          <div className="entry-item">
            <label htmlFor="signInName">Email Address</label>
            <div
              className="error itemLevel"
              aria-hidden="true"
              role="alert"
              style={{ display: errorDisplay }}
            >
              <p></p>
            </div>
            <input
              type="email"
              id="signInName"
              name="Email Address"
              title="Please enter a valid Email Address"
              pattern="^[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
              autoFocus={false}
              placeholder="Email Address"
              aria-label="Email Address"
            />
          </div>
          <div className="entry-item">
            <div className="password-label">
              <label htmlFor="password">Password</label>
              <a
                id="forgotPassword"
                href="/sienaqab2c.onmicrosoft.com/B2C_1_signin/api/CombinedSigninAndSignup/unified?claimsexchange=ForgotPassword&amp;csrf_token=QVRuR1ZrMHNybG5rcUdJVXkxQm9IK2ZoQjhQeXNBL0R5eU1ZOFhkQTNjZm9pRjhXaEwzSDdFMStab3VieitQaFI1cXVFNzA0cEx2UG5FZ3I1UmNtRkE9PTsyMDIyLTA0LTE1VDE3OjI5OjU5LjE4ODY4NDNaO2JDMXE3bTZOSi9NYWJ0NC9LdnkzeHc9PTt7Ik9yY2hlc3RyYXRpb25TdGVwIjoxfQ==&amp;tx=StateProperties=eyJUSUQiOiI3MzlmOWQzMC02MjU0LTRhYzYtOGM5Yi1iM2Q2MTQxOWIxYWMifQ&amp;p=B2C_1_signin"
              >
                Forgot your password?
              </a>
            </div>
            <div
              className="error itemLevel"
              aria-hidden="true"
              style={{ display: errorDisplay }}
            >
              <p role="alert"></p>
            </div>
            <input
              type="password"
              id="password"
              name="Password"
              placeholder="Password"
              aria-label="Password"
              aria-required="true"
            />
          </div>
          <div className="working"></div>

          <div className="buttons">
            <button id="next" type="submit" form="localAccountForm">
              Sign in
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
