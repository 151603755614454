import { useState } from "react"
import { BoxProps, Button, ButtonGroup, HStack } from "@chakra-ui/react"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
  CopyIcon,
  DownloadIcon,
  HamburgerIcon,
} from "@chakra-ui/icons"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "utilities/constants"
import { getPageHTML } from "pages/susi/utils/getPageHTML"

interface AuthToolbarProps extends BoxProps {
  route:
    | AppRoutes.authForgotPassword
    | AppRoutes.authSignIn
    | AppRoutes.authSignUp
}

const AUTH_ROUTES = {
  [AppRoutes.authSignUp]: {
    next: AppRoutes.authSignIn,
    prev: AppRoutes.authForgotPassword,
  },
  [AppRoutes.authSignIn]: {
    next: AppRoutes.authForgotPassword,
    prev: AppRoutes.authSignUp,
  },
  [AppRoutes.authForgotPassword]: {
    next: AppRoutes.authSignUp,
    prev: AppRoutes.authSignIn,
  },
}

export const AuthToolbar = ({ route }: AuthToolbarProps) => {
  const [showToolbar, setShowToolbar] = useState(true)
  const [isCodeCopied, setIsCodeCopied] = useState(false)
  const navigate = useNavigate()

  const handleNavigation = (direction: "next" | "prev") => {
    const nextRoute = AUTH_ROUTES[route][direction]
    navigate(`/${nextRoute}`)
  }

  const getFileName = () => {
    switch (route) {
      case AppRoutes.authSignIn:
        return "signin.html"
      case AppRoutes.authSignUp:
        return "signup.html"
      case AppRoutes.authForgotPassword:
        return "forgotpassword.html"
      default:
        return "auth.html"
    }
  }

  const copyPageHTML = async () => {
    const html = getPageHTML()
    await navigator.clipboard.writeText(html)
    setIsCodeCopied(true)
    setTimeout(() => setIsCodeCopied(false), 2000)
  }

  const savePageAsFile = () => {
    const html = getPageHTML()
    const blob = new Blob([html], { type: "text/html" })
    const a = document.createElement("a")
    a.href = URL.createObjectURL(blob)
    a.download = getFileName()
    a.hidden = true
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <HStack
      className="auth-route-nav"
      background="none"
      display={{ base: "none", sm: "flex" }}
      justifyContent="space-between"
      padding={2}
      position="absolute"
      width="fit-content"
      height="fit-content"
      zIndex="skipLink"
      top={0}
      right={0}
    >
      {showToolbar ? (
        <ButtonGroup variant="outline" size="sm">
          <Button onClick={savePageAsFile}>
            <DownloadIcon boxSize="5" mr="2" />
            Download
          </Button>
          <Button onClick={copyPageHTML} isDisabled={isCodeCopied}>
            <CopyIcon boxSize="5" mr="2" /> {isCodeCopied ? "Copied!" : "Copy"}
          </Button>

          <ButtonGroup isAttached>
            <Button
              title={`Previous Page (${AUTH_ROUTES[route].prev})`}
              onClick={() => handleNavigation("prev")}
            >
              <ArrowLeftIcon boxSize="4" />
            </Button>
            <Button
              title={`Next Page (${AUTH_ROUTES[route].next})`}
              onClick={() => handleNavigation("next")}
            >
              <ArrowRightIcon boxSize="4" />
            </Button>
          </ButtonGroup>

          <Button title="Hide Toolbar" onClick={() => setShowToolbar(false)}>
            <CloseIcon boxSize="4" />
          </Button>
        </ButtonGroup>
      ) : (
        <Button
          variant="outline"
          size="sm"
          title="Copy or Download HTML"
          onClick={() => setShowToolbar(true)}
        >
          <HamburgerIcon boxSize="5" />
        </Button>
      )}
    </HStack>
  )
}
