import { createContext, PropsWithChildren, useCallback, useMemo } from "react"
import { ChildrenProps } from "./types/context.types"
import { useLocalStorage } from "hooks/"

interface AuthContextState {
  token: string | undefined
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>
  adb2cToken: string | undefined
  setAdb2cToken: React.Dispatch<React.SetStateAction<string | undefined>>
  unsetTokens(): void
}

const initialValue: AuthContextState = {
  token: undefined,
  setToken: () => {},
  adb2cToken: undefined,
  setAdb2cToken: () => {},
  unsetTokens: () => {},
}

export const AuthContext = createContext(initialValue)

export const AuthProvider: React.FC<PropsWithChildren<ChildrenProps>> = ({
  children,
}) => {
  const [token, setToken] = useLocalStorage<string | undefined>(
    "token",
    undefined,
  )
  const [adb2cToken, setAdb2cToken] = useLocalStorage<string | undefined>(
    "adb2cToken",
    undefined,
  )

  const unsetTokens = useCallback(() => {
    setToken(undefined)
    setAdb2cToken(undefined)
  }, [setToken, setAdb2cToken])

  const contextValue = useMemo(
    () => ({ token, setToken, adb2cToken, setAdb2cToken, unsetTokens }),
    [token, setToken, adb2cToken, setAdb2cToken, unsetTokens],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
