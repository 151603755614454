/**
 * This is a copy of the Forgot Password form that Azure injects into <div id="api"></div>
 * It exists here to provide classNames to reference for styling and istemporarily removed
 * from the DOM when a developer clicks the "Copy HTML" button.
 */

export const AzureForgotPasswordForm = (props: { showErrors: boolean }) => {
  const errorDisplay = props.showErrors ? "block" : "none"

  return (
    <div id="api">
      <div className="heading">
        <h1>User Details</h1>
      </div>
      <div className="buttons"></div>
      <div className="intro">
        <p>Please provide the following details.</p>
      </div>
      <form id="attributeVerification">
        <div
          id="passwordEntryMismatch"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        >
          The password entry fields do not match. Please enter the same password
          in both fields and try again.
        </div>
        <div
          id="requiredFieldMissing"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        >
          A required field is missing. Please fill out all required fields and
          try again.
        </div>
        <div
          id="fieldIncorrect"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        >
          One or more fields are filled out incorrectly. Please check your
          entries and try again.
        </div>
        <div
          id="claimVerificationServerError"
          className="error pageLevel"
          style={{ display: errorDisplay }}
          aria-hidden="true"
        ></div>
        <div id="attributeList" className="attr">
          <ul>
            <li className="VerificationControl emailVerificationControl_li">
              <div className="attrEntry">
                <label
                  id="emailVerificationControl_label"
                  htmlFor="emailVerificationControl"
                ></label>
                <div className="error itemLevel" role="alert"></div>
                <div
                  className="verificationControlContent"
                  id="emailVerificationControl"
                >
                  <div className="verificationInfoText">
                    <div
                      id="emailVerificationControl_info_message"
                      aria-hidden="true"
                      role="alert"
                      aria-label="Verification is necessary. Please click Send button."
                      style={{ display: errorDisplay }}
                    >
                      Verification is necessary. Please click Send button.
                    </div>
                  </div>
                  <div className="verificationSuccessText">
                    <div
                      id="emailVerificationControl_success_message"
                      aria-hidden="true"
                      role="alert"
                      aria-label=""
                      style={{ display: errorDisplay }}
                    ></div>
                  </div>
                  <div className="verificationErrorText error">
                    <div
                      id="emailVerificationControl_error_message"
                      aria-hidden="true"
                      role="alert"
                      aria-label=""
                      style={{ display: errorDisplay }}
                    ></div>
                  </div>
                  <ul>
                    <li
                      className="TextBox email_li email"
                      aria-hidden="false"
                      style={{ display: "inline" }}
                    >
                      <div className="attrEntry">
                        <label id="email_label" htmlFor="email">
                          Email Address
                        </label>
                        <div className="error itemLevel" role="alert"></div>
                        <input
                          id="email"
                          className="textInput"
                          type="text"
                          placeholder="Email Address"
                          aria-label="Email Address"
                          pattern="^[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
                          title="Email address that can be used to contact you."
                          aria-required="true"
                          autoFocus={true}
                        />

                        <a
                          className="helpLink tiny"
                          href="https://#"
                          data-help="Email address that can be used to contact you."
                        >
                          What is this?
                        </a>
                      </div>
                    </li>
                    <li
                      className="TextBox emailVerificationCode_li VerificationCode"
                      aria-hidden="true"
                      style={{ display: errorDisplay }}
                    >
                      <div className="attrEntry">
                        <label
                          id="emailVerificationCode_label"
                          htmlFor="emailVerificationCode"
                        >
                          Verification Code
                        </label>
                        <div className="error itemLevel" role="alert"></div>
                        <input
                          id="emailVerificationCode"
                          className="textInput"
                          type="text"
                          placeholder="Verification Code"
                          aria-label="Verification Code"
                          title="Enter your verification code"
                          aria-required="true"
                          autoComplete="off"
                          disabled={false}
                        />
                        <a
                          className="helpLink tiny"
                          href="https://#"
                          data-help="Enter your verification code"
                        >
                          What is this?
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div
                    className="working"
                    style={{ display: errorDisplay }}
                    aria-hidden="true"
                  ></div>
                  <div className="buttons">
                    <button
                      className="sendCode"
                      id="emailVerificationControl_but_send_code"
                      type="button"
                      aria-label="Send verification code"
                      aria-hidden="false"
                      style={{ display: "inline" }}
                    >
                      Send verification code
                    </button>
                    <button
                      className="verifyCode"
                      id="emailVerificationControl_but_verify_code"
                      type="button"
                      aria-label="Verify code"
                      aria-hidden="true"
                      style={{ display: errorDisplay }}
                    >
                      Verify code
                    </button>
                    <button
                      className="sendNewCode"
                      id="emailVerificationControl_but_send_new_code"
                      type="button"
                      aria-label="Send new code"
                      aria-hidden="true"
                      style={{ display: errorDisplay }}
                    >
                      Send new code
                    </button>
                    <button
                      className="changeClaims"
                      id="emailVerificationControl_but_change_claims"
                      type="button"
                      aria-label="Change e-mail Email Address"
                      aria-hidden="true"
                      style={{ display: errorDisplay }}
                    >
                      Change e-mail
                    </button>
                  </div>
                </div>
                <a className="helpLink tiny" href="https://#">
                  What is this?
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons">
          <button
            id="continue"
            type="submit"
            form="attributeVerification"
            aria-disabled="false"
            aria-label="Continue"
          >
            Continue
          </button>
          <button id="cancel" aria-label="Cancel" formNoValidate={false}>
            Cancel
          </button>
        </div>

        <div className="verifying-modal">
          <div id="verifying_blurb"></div>
        </div>
      </form>
    </div>
  )
}
