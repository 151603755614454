export const getPageHTML = () => {
  // Get the div that Azure will inject content info
  const azureForm = document.getElementById("api")

  if (!azureForm) {
    console.error("Azure form element not found")
    return ""
  }

  // Save the value before we clear it to copy / paste
  const initialAzureFormHTML = azureForm.innerHTML

  // Clear the cloned form to return the empty `<div id="api"></div>` tag for Azure
  azureForm.innerHTML = ""

  // Clone the entire document
  const clonedDoc = document.documentElement.cloneNode(true) as HTMLElement

  // Find and remove the auth route nav from the cloned document
  const authRouteNav = clonedDoc.querySelector(".auth-route-nav")
  if (authRouteNav?.parentNode) {
    authRouteNav.parentNode.removeChild(authRouteNav)
  } else {
    console.warn("AuthToolbar element not found in cloned document")
  }

  // Remove dev tools button
  const devToolsButton = clonedDoc.querySelector("#dev-tools-trigger")
  if (devToolsButton?.parentNode) {
    devToolsButton.parentNode.removeChild(devToolsButton)
  }

  // Remove specific scripts and chrome extension elements
  const scriptsToRemove = clonedDoc.querySelectorAll(
    `script[src='/env.js'],
         script[src='/static/js/bundle.js'],
         script[src*='LastPass'],
         [data-lastpass-root], 
         [data-lastpass-icon-root],
         #awebChromeHelper,
         [id^='chrome-extension'],
         link[href*='chrome-extension'],
         .react-tel-input, 
         [class*='react-tel-input']`,
  )
  scriptsToRemove.forEach(el => el.parentNode?.removeChild(el))

  // Remove react-tel-input style blocks
  const styleElements = clonedDoc.getElementsByTagName("style")
  for (let i = styleElements.length - 1; i >= 0; i--) {
    const style = styleElements[i]
    if (style?.textContent?.includes("react-tel-input")) {
      style.parentNode?.removeChild(style)
    }
  }

  // Get the HTML from the modified cloned document
  let html = clonedDoc.outerHTML

  // Remove any remaining LastPass-related attributes
  html = html.replace(/\s*data-lastpass-[^=]+="[^"]*"/g, "")

  // Reset Azure form HTML
  azureForm.innerHTML = initialAzureFormHTML

  return html
}
