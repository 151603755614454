import { createContext, useState, useEffect, useMemo } from "react"
import { Client } from "api/client"
import tealiumInit from "tealium/tealium-init"
import { AuthRoute, AuthRoutes } from "utilities/constants"

interface ClientContextState {
  client: Client
  setClient: (client: Client) => void
}

const initialValue: ClientContextState = {
  client: undefined,
  setClient: () => {},
}

export const ClientContext = createContext(initialValue)

export const ClientProvider = ({ children }: React.PropsWithChildren) => {
  const [client, setClient] = useState<Client>()

  useEffect(() => {
    if (client) {
      const currentPath = window.location.pathname.slice(1)
      const isAuthPage = AuthRoutes.includes(currentPath as AuthRoute)

      const { cleanup } = tealiumInit({
        clientTheme: client.theme,
        isAuthPage,
      })

      return cleanup
    }
  }, [client])

  const value = useMemo(() => ({ client, setClient }), [client, setClient])

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  )
}
