import { useLayoutEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "context/AuthContext"
import { ClientProvider } from "context/ClientContext"
import { UserProvider } from "context/UserContext"
import { ErrorProvider } from "context/ErrorContext"
import { AuthenticationWrapper } from "components/AuthenticationWrapper"
import CIDWrapper from "components/CidWrapper"
import ClientWrapper from "components/ClientWrapper"
import ThemeWrapper from "components/ThemeWrapper"
import AppErrorModal from "components/AppErrorModal"
import { COLLEGES } from "data/colleges"
import { setupQueryParamsToLocalStorage } from "utilities/helpers"

// Data and theme used during development
const localCollege = COLLEGES.find(local => local.currentlyActive)

export const App = () => {
  useLayoutEffect(() => {
    setupQueryParamsToLocalStorage()
  }, [])

  return (
    <BrowserRouter>
      <ClientProvider>
        <ThemeWrapper>
          <ErrorProvider>
            <AuthProvider>
              <UserProvider>
                <CIDWrapper localCID={localCollege.collegeId}>
                  <ClientWrapper>
                    <AuthenticationWrapper {...localCollege.data} />
                    <AppErrorModal />
                  </ClientWrapper>
                </CIDWrapper>
              </UserProvider>
            </AuthProvider>
          </ErrorProvider>
        </ThemeWrapper>
      </ClientProvider>
    </BrowserRouter>
  )
}
