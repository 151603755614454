import {
  Box,
  CloseButton,
  Icon,
  Spinner,
  Stack,
  Text,
  forwardRef,
} from "@chakra-ui/react"
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons"
import { MdPublish } from "react-icons/md"
import {
  ForwardedRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { FileRejection, useDropzone } from "react-dropzone"
import { FormControl, Input, Span } from "components/common"
import { UserApi } from "api/user"
import { AuthContext } from "context/AuthContext"
import { isAuthorizationError } from "utilities/helpers"
import { useNavigate } from "react-router-dom"
import { useErrorModal } from "context/ErrorContext"

export const ACCEPTED_FILE_TYPES = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
}

export const FILE_ERROR_MESSAGES = {
  required: "This field is required. Please upload a file.",
  invalidType: "Invalid file type. Please upload a .pdf, .doc, or .docx file.",
  tooLarge: "File is too large. Maximum file size is 10 MB.",
}

export interface FileUploadProps {
  errorText?: string
  helperText?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isReadOnly?: boolean
  isRequired?: boolean
  label?: React.ReactNode
  onChange: (value: string) => void
  onBlur: () => void
  value?: string
  [key: string]: any
}

export const FileUpload = forwardRef(
  (
    {
      errorText,
      helperText = "Upload a .pdf, .doc, or .docx",
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      label,
      onChange,
      onBlur,
      value,
      ...rest
    }: FileUploadProps,
    ref: ForwardedRef<HTMLFieldSetElement>,
  ) => {
    const { token } = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [hasFile, setHasFile] = useState<boolean>(false)
    const [fileError, setFileError] = useState<string | null>(null)
    const navigate = useNavigate()
    const { handleError } = useErrorModal()

    const handleDrop = useCallback(
      (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        setIsUploading(false)
        if (fileRejections.length > 0) {
          handleFileRejection(fileRejections[0], setFileError)
          onChange("")
          setHasFile(false)
          return
        }

        setFileError(null)

        if (acceptedFiles.length > 0) {
          handleFileUpload(acceptedFiles[0])
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [token, onChange, onBlur, navigate],
    )

    const handleFileUpload = (file: File) => {
      const formData = new FormData()
      formData.append("file", file)

      setIsLoading(true)
      setIsUploading(true)

      UserApi.uploadEssay({ token: token, file: formData })
        .then(() => {
          onChange(file.name)
          setHasFile(true)
          onBlur()
        })
        .catch(handleUploadError)
        .finally(() => {
          setIsLoading(false)
          setIsUploading(false)
        })
    }

    const handleUploadError = (err: any) => {
      if (isAuthorizationError(err)) {
        handleError({
          action: "logout",
          log: { message: "Authorization error during upload", err },
        })
      } else {
        console.error(err)
        onChange("")
        setHasFile(false)
      }
    }

    const {
      acceptedFiles,
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      open,
    } = useDropzone({
      accept: ACCEPTED_FILE_TYPES,
      onDrop: handleDrop,
      maxFiles: 1,
      maxSize: 10485760, // 10 MB
      noClick: hasFile,
      noKeyboard: hasFile,
      onFileDialogCancel: () => setIsUploading(false),
    })

    const handleDelete = () => {
      acceptedFiles.splice(0, acceptedFiles.length)
      onChange("")
      setHasFile(false)
      onBlur()
    }

    const isValid = !(fileError || errorText || isInvalid)

    const getBaseStyles = (hasFile: boolean, isValid: boolean) => ({
      alignItems: "center",
      border: hasFile ? "none" : "2px dashed",
      borderColor: isValid ? "formInputBorderColor" : "error",
      background: isValid
        ? "formInputBackgroundColor"
        : "formInputFocusedBackgroundColor",
      color: isValid ? "buttonLinkColor" : "errorText",
      cursor: hasFile ? "default" : "pointer",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      padding: hasFile ? 0 : 6,
      transition: "all 0.2s",
    })

    const getHoverStyles = (isValid: boolean) => ({
      borderColor: isValid ? "formInputHoveredBorderColor" : "error",
    })

    const getFocusStyles = (isValid: boolean) => ({
      background: "formInputFocusedBackgroundColor",
      borderColor: isValid ? "formInputFocusedBorderColor" : "error",
      boxShadow: isValid ? null : "inputFocusedError",
    })

    const getDragStyles = (
      isDragging: boolean,
      hasFile: boolean,
      isError: boolean = false,
    ) => {
      if (!isDragging || hasFile) return {}
      return {
        background: "formInputFocusedBackgroundColor",
        ...(isError ? { borderColor: "error!", color: "errorText" } : {}),
      }
    }

    const getUploadingStyles = (
      isUploading: boolean,
      hasFile: boolean,
      isValid: boolean,
    ) => {
      if (!isUploading || hasFile) return {}
      return {
        background: "formInputFocusedBackgroundColor",
        borderColor: isValid ? "formInputFocusedBorderColor" : "error",
        boxShadow: isValid ? "inputFocused" : "inputFocusedError",
      }
    }

    const styles = useMemo(
      () => ({
        ...getBaseStyles(hasFile, isValid),
        _hover: getHoverStyles(isValid),
        _focusVisible: {
          zIndex: 1,
          borderColor: "#3182ce",
          boxShadow: "0 0 0 1px #3182ce",
        },
        _focus: getFocusStyles(isValid),
        _invalid: {
          borderColor: "error",
          boxShadow: "none",
          backgroundColor: "formInputFocusedBackgroundColor",
        },
        _groupHover: {
          "& .link": { textDecoration: "underline" },
        },
        ...getDragStyles(isDragActive, hasFile),
        ...getDragStyles(isDragAccept, hasFile),
        ...getDragStyles(isDragReject, hasFile, true),
        ...getUploadingStyles(isUploading, hasFile, isValid),
      }),
      [hasFile, isValid, isDragActive, isDragAccept, isDragReject, isUploading],
    )

    useEffect(() => {
      if (value && !hasFile) {
        setHasFile(true)
      }
    }, [value, hasFile])

    const handleClick = (e: React.MouseEvent) => {
      if (!hasFile) {
        setIsUploading(true)
        open()
      } else {
        e.preventDefault()
      }
    }

    const getUploaderIcon = () => {
      if (isDragAccept) return CheckCircleIcon
      if (isDragReject) return WarningIcon
      return MdPublish
    }

    const UploaderIcon = getUploaderIcon()

    return (
      <FormControl
        ref={ref}
        helperText={helperText}
        isDisabled={isDisabled}
        isInvalid={!isValid}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        errorText={fileError || errorText}
        label={label}
        {...rest}
      >
        <Box {...getRootProps()} {...styles} onClick={handleClick}>
          <Input
            {...getInputProps()}
            size="md"
            cursor={hasFile ? "text" : "pointer"}
          />
          {renderContent()}
        </Box>
      </FormControl>
    )

    function renderContent() {
      if (!hasFile) {
        return (
          <Stack spacing={0} alignItems="center">
            {isUploading || isLoading ? (
              <Spinner size="md" mb={1} />
            ) : (
              <Icon boxSize="6" mb={1} as={UploaderIcon} />
            )}
            <Text>
              {isDragReject ? (
                <Span color="errorText">
                  File is invalid. Please try another.
                </Span>
              ) : (
                <>
                  <Span className="link">Choose a file</Span>
                  <Span color="bodyColor">{` or drag it here `}</Span>
                </>
              )}
            </Text>
          </Stack>
        )
      }

      return (
        <Box position="relative" width="100%">
          <Input
            value={value || acceptedFiles[0]?.name || ""}
            accept={Object.values(ACCEPTED_FILE_TYPES).flat().join(",")}
            readOnly
            paddingLeft="2.5rem"
            paddingRight="2.5rem"
            cursor="text"
            pointerEvents="auto"
            onClick={e => e.stopPropagation()}
            _focus={{ outline: "none" }}
          />
          <Box
            position="absolute"
            left="0.5rem"
            top="50%"
            transform="translateY(-50%)"
            pointerEvents="none"
          >
            {renderStatusIcon()}
          </Box>
          <Box
            position="absolute"
            right="0.5rem"
            top="50%"
            transform="translateY(-50%)"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <CloseButton
                color="bodyColor"
                onClick={e => {
                  e.stopPropagation()
                  handleDelete()
                }}
                title="Remove File"
                pointerEvents="auto"
              />
            )}
          </Box>
        </Box>
      )
    }

    function renderStatusIcon() {
      if (isLoading) return null
      return isValid ? (
        <CheckCircleIcon color="success" cursor="default" />
      ) : (
        <WarningIcon color="errorText" />
      )
    }
  },
)

function handleFileRejection(
  fileRejection: FileRejection,
  setFileError: (error: string) => void,
) {
  const typeError = fileRejection.errors.find(
    e => e.code === "file-invalid-type",
  )
  const sizeError = fileRejection.errors.find(e => e.code === "file-too-large")

  if (typeError) {
    setFileError(FILE_ERROR_MESSAGES.invalidType)
  } else if (sizeError) {
    setFileError(FILE_ERROR_MESSAGES.tooLarge)
  }
}
