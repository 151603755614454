import { ErrorProps } from "components/AppErrorModal"
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react"

type ErrorContextType = {
  error: ErrorProps | null
  handleError: (error: ErrorProps) => void
  clearError: () => void
}

const defaultErrorContext: ErrorContextType = {
  error: null,
  handleError: () => {},
  clearError: () => {},
}

const ErrorContext = createContext<ErrorContextType>(defaultErrorContext)

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState<ErrorProps | null>(null)

  const handleError = useCallback(
    ({ title, message, action, log }: ErrorProps) => {
      setError({ title, message, action, log })
    },
    [],
  )

  const clearError = useCallback(() => {
    setError(null)
  }, [])

  const contextValue = useMemo(
    () => ({ error, handleError, clearError }),
    [clearError, error, handleError],
  )

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  )
}

export const useErrorModal = () => {
  const context = useContext(ErrorContext)
  if (context === undefined) {
    throw new Error("useErrorModal must be used within an ErrorProvider")
  }
  return context
}
